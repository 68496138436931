import { Service, ServiceBlue, NewService } from '../../components'
import { product10, service10, service11, service3, service4, catalogue2, catalogue3, catalogue4, catalogue5, catalogue6, service12, catalogue7 } from '../../assets'
import './catalogueView.css'
export const CatalogueView = () => {
    return (
        <>
            <NewService img={service12} title='CATÁLOGO' secondTitle='STANDS' desc='Hacemos realidad cualquier idea, encuentra ejemplos de nuestro trabajo aquí ' button={true} linkButton={`${catalogue7}`} />
            <div className='catalogueView'>
                <Service button={true} img={product10} linkButton='https://online.flippingbook.com/view/939406/2/' title='CATÁLOGO' seconTitle='PROMOCIONALES' desc='Encuentra muchas opciones y si no la encuentras llamanos tenemos mas catálogos para ti.' />
                <ServiceBlue button={true} img={service10} linkButton={`${catalogue2}`} title='CATÁLOGO' secondTitle='RECONOCIMIENTOS' desc='Reconocimientos y placas conmemorativas pregunta por todas las opciones' />
                <Service button={true} img={service11} linkButton={`${catalogue3}`} title='CATÁLOGO' seconTitle='ELECTRÓNICOS' desc='Contamos con un amplio catálogo de productos electrónicos, como USB´s y baterías recargables de la mejor calidad' />
                <Service button={true} img={service4} linkButton={`${catalogue4}`} title='CATÁLOGO DE' seconTitle='TEMPORADAS' desc='Contamos con una amplia gama de productos y promocionales para fechas especiales como día de las madres, día del padre, día del niño, día de la Mujer etc. Conoce todos nuestros productos.' />
                <ServiceBlue button={true} img={service3} linkButton={`${catalogue5}`} title='CATÁLOGO' secondTitle='DELUXE' desc='Encuentra artículos promocionales y regalos de alta calidad para tus clientes y colaboradores más especiales.' />
                <Service button={true} img={service4} linkButton={`${catalogue6}`} title='CATÁLOGO' seconTitle='TEXTIL' desc='Catálogo de textiles, playeras, sudaderas, chamarras, gorras y mucho más, si no lo encuentras pregunta por más opciones.' />
            </div></>
    )
}
